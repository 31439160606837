import {handleErrorWithSentry, browserTracingIntegration, extraErrorDataIntegration} from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import {HttpError, TimeoutError} from '@canals/ui/src/api-client/http_client';
import {PUBLIC_IS_LOCAL, PUBLIC_SENTRY_DSN, PUBLIC_CANALS_ENV} from '$env/static/public';
import {ACCOUNT_DISABLED, ORDER_SUBMITTED} from '@canals/shared/src/api_error_codes';
import {isErrorAlreadyReported} from '@canals/ui/src/utils/errors';
import type {HandleClientError} from '@sveltejs/kit';
import {dynamicImportError} from '@canals/ui/src/stores';

// Set this to true when you are working with Sentry locally and want all
// data to be sent to Sentry. For this to work, you must override the
// PUBLIC_SENTRY_DSN environment variable in your config/.env.local file.
// NOTE: Keep the TS type to prevent committing with debug mode enabled.
const DEBUG_MODE: false = false as const;

// Track if an error occurred during the current page load. If so,
// we want to trace all transactions associated.
let errorOccurred = false;

function debugLog(message: string): void {
  if (DEBUG_MODE) console.log(message);
}

function isDynamicLoadError(error: unknown): error is TypeError {
  return error instanceof TypeError && error.message.startsWith('Failed to fetch dynamically imported module');
}

function getSampleRateForTransaction(): number {
  if (DEBUG_MODE) return 1;

  // There was an error during this transaction, so always trace it.
  if (errorOccurred) {
    debugLog('sending transaction because there was an error');
    return 1;
  }

  return 0.05;
}

function getComponent(): 'ap' | 'order-entry' {
  const url = window.location.href;
  const isAp = /ap(\/|$)/.test(url);
  return isAp ? 'ap' : 'order-entry';
}

if (PUBLIC_IS_LOCAL === 'false' || DEBUG_MODE) {
  Sentry.init({
    debug: DEBUG_MODE,
    dsn: PUBLIC_SENTRY_DSN,
    environment: PUBLIC_CANALS_ENV,
    integrations: [browserTracingIntegration(), extraErrorDataIntegration({depth: 10})],

    tracesSampleRate: 1, // Always enabled, but only sent based on beforeSendTransaction.
    tracePropagationTargets: ['localhost', /(.*\.)?api.canals.ai/],

    beforeSendTransaction(transaction) {
      const sampleRate = getSampleRateForTransaction();

      if (Math.random() > 1 - sampleRate) {
        debugLog(`sending transaction (sample rate = ${sampleRate})`);
        return transaction;
      }

      return null;
    },

    async beforeSend(event, hint) {
      // These errors get wrapped and thrown at a later time if the page is
      // still loaded after a short period of time. See `customClientErrorHandler`
      // below for more details.
      if (isDynamicLoadError(hint.originalException)) return null;

      // Indicate that an error occurred so we know that tracing
      // should be enabled for all subsequent transactions.
      errorOccurred = true;

      // If the error is expected, then don't send it to Sentry.
      if (isErrorAlreadyReported(hint.originalException)) return null;

      // Would've already been reported by the backend
      if (hint.originalException instanceof HttpError && hint.originalException.statusCode === 500) return null;
      if (hint.originalException instanceof HttpError && hint.originalException.code === ACCOUNT_DISABLED) return null;

      // Will happen sometimes if user has submitted order in another tab, so don't send an alert
      if (hint.originalException instanceof HttpError && hint.originalException.code === ORDER_SUBMITTED) return null;

      // Usually a superuser goes to some page while impersonating someone who doesn't have access to it
      if (hint.originalException instanceof HttpError && hint.originalException.statusCode === 403) return null;

      if (
        hint.originalException instanceof Error &&
        hint.originalException.message.startsWith(
          'Non-Error promise rejection captured with value: Object Not Found Matching'
        )
      )
        return null;

      // Also most often a superuser opening an order while impersonating someone from another org
      if (hint.originalException instanceof HttpError && hint.originalException.statusCode === 404) return null;

      if (hint.originalException instanceof HttpError && hint.originalException.statusCode === 401) {
        Sentry.addBreadcrumb({
          category: 'auth',
          message: `Received 401 from API. User: ${event.user?.id}. Path: ${window.location.pathname}`,
        });
      }

      // User has logged out and was navigated to login page, but there was a pending request that then got rejected
      if (hint.originalException instanceof HttpError && hint.originalException.statusCode === 401 && !event.user) {
        // Wait for the url to change to /login
        await new Promise((resolve) => setTimeout(resolve, 300));
        if (window.location.pathname === '/login') return null;
      }

      if (hint.originalException instanceof HttpError && hint.originalException.statusCode === 401 && event.user) {
        // User's session probably expired.
        window.openSessionExpiredModal();
        return null;
      }

      if (hint.originalException instanceof TimeoutError) return null;
      if (event.exception?.values?.[0]?.value?.includes('Cannot redefine property: googletag')) return null; // Adblocker: https://stackoverflow.com/questions/78103254/cannot-redefine-property-googletag
      if (event.exception?.values?.[0]?.value === 'Network Error') return null;

      event.tags = {...event.tags, component: getComponent()};
      return event;
    },
  });
}

// Sentry has a `handleErrorWithSentry` function, but that doesn't return an object,
// which can mess up sveltekit processing further down the line. Instead, we use our
// own modified version of the code to handle errors. This allows us to further filter
// what is being sent to Sentry and add additional handling for special cases.
export const handleError: HandleClientError = async (input) => {
  const {error, ...details} = input;

  let reportErrorWithSentry = true;

  // These errors can be thrown when preloading dependencies for a page that
  // we have not tried to navigate to yet. When that happens, we don't want
  // to report the error right away because the user may not actually end up
  // navigating to that page. Instead, we keep track that an error has occurred
  // and the next navigation will trigger a full reload of the page, which
  // should resolve any problems with the dynamic import due to version skew
  // between the initial page load and the currently deployed version of the
  // app.
  if (isDynamicLoadError(error)) {
    dynamicImportError.set(true);
    reportErrorWithSentry = false;
    Sentry.addBreadcrumb({data: input, category: 'app', message: 'forcing reload because of dynamic import error'});
  }

  if (reportErrorWithSentry) {
    // @ts-expect-error - see https://tinyurl.com/mr2dd2m3
    handleErrorWithSentry()(input);
  }

  return {
    message: details.message,
  };
};
