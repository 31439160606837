import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/accept-invite": [3],
		"/ap": [4],
		"/ap/reviews/[[tab]]": [6],
		"/ap/[id]/[[tab]]": [5],
		"/audit": [7],
		"/erp-requests": [8],
		"/erp-syncs": [9],
		"/forgot-password": [10],
		"/gpt-prompts": [11],
		"/inbox": [12],
		"/llms": [13],
		"/login": [14],
		"/login/sso": [15],
		"/match": [16],
		"/onboarding": [17],
		"/onboarding/erp_connection": [18],
		"/onboarding/name": [19],
		"/onboarding/questionnaire": [20],
		"/order/[id]": [21],
		"/order/[id]/[tab]": [22],
		"/organizations": [25],
		"/organization/new": [24],
		"/organization/[id]": [23],
		"/reporting": [26],
		"/reporting/reports": [27],
		"/reporting/usage": [28],
		"/request-format": [29],
		"/request-format/success": [30],
		"/reset-password": [31],
		"/search": [32],
		"/settings": [33],
		"/settings/account": [34],
		"/settings/auto-submit": [35],
		"/settings/inbox": [36],
		"/settings/teams": [37],
		"/settings/users": [38],
		"/training-examples": [39],
		"/validate-ftp": [40],
		"/verify-email": [41],
		"/verify-email/[token]": [42]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';